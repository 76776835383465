<template>
    <div class="field">
        <div class="checkbox-wrapper">
            <label>
                <input
                    type="checkbox"
                    :checked="checked"
                    @input="updateValue($event.srcElement.checked)"
                />
                <span class="checkmark"></span>
                {{ label }}
            </label>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CheckboxField',

        props: [
            'value',
            'label',
            'checked'
        ],

        methods: {
            updateValue(value) {
                this.$emit('input', value)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .checkbox-wrapper {
        position: relative;

        label {
            display:flex;
            align-items: center;
            cursor: pointer;
            align-content: center;
        }

        input {
            opacity: 0;
            margin-right: 20px;

            &:checked {
                ~ .checkmark {
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }

        .checkmark {
            position: absolute;
            left: 0;
            height: 20px;
            width: 20px;
            border: 1px solid $timberGreen;

            &:after {
                content: "";
                position: absolute;
                width: 10px;
                height: 10px;
                bottom: 5px;
                left: 5px;
                background: $timberGreen;
                opacity: 0;
            }
        }
    }
</style>
